@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

body {
    font-family: 'Noto Sans' !important;
    overflow: hidden;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #939292;
}

.MuiButton-root.MuiButton-contained {
    @apply text-white font-semibold;
}
    

.custom-card-shadow {
    box-shadow: 3.14057px 3.14057px 3.14057px rgba(0, 0, 0, 0.15);
}

.divide-gradient-gray {
    background: linear-gradient(90deg, #47484C 0%, rgba(240, 240, 240, 0) 100%);
}

.adjust-height {
    height: 750px;
}

.fixed-screen-height {
    height: calc(100vh - 180px);
}


/* ----------- iPad Pro ----------- */


/* Portrait and Landscape */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .adjust-height {
        height: 1000px;
    }
}


/* Portrait */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .adjust-height {
        height: 1000px;
    }
}


/* Landscape */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    .adjust-height {
        height: 700px;
    }
}